import type { FC } from "react";
import dynamic from "next/dynamic";
import LazyHydrate from "react-lazy-hydration";
import { StyledCallContainer } from "./StyledCallInfo";
import LinkList from "@/components/List/LinkList/LinkList";
import { lazyHydrationConfig } from "@/hooks/lazyHydrate";
import { useWindowSize } from "@/hooks/windowSize";
import { ListGroup } from "@/layouts/Default/Footer/StyledFooter";
import { getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { Button } from "@/ui/Button/Button";
import { cssButtonClean } from "@/ui/Button/StyledButton";
import { Link } from "@/ui/Link";
import { LinkVariants } from "@/ui/Link/constants";
import { Modal } from "@/ui/Modal/Modal";
import { Popover } from "@/ui/Popover/Popover";
import { Typography } from "@/ui/Typography/Typography";
import { CONTACT_PHONE, PHONES_ITEMS } from "@/utils/constants";
const Recall = dynamic(() => import("@/components/Forms/Recall").then(mod => mod.Recall), {
  ssr: false
});
export const CallInfo: FC = () => {
  const {
    width
  } = useWindowSize();
  const isResponsMode = width !== undefined && width <= getBreakpointVal(breakpoints.lg);
  return <StyledCallContainer>
      <Popover offset={[0, -2]} withHover={!isResponsMode} placement={"bottom-start"} disclosure={<Button className={cssButtonClean}>
            <Typography variant={"h3"}>
              {isResponsMode ? CONTACT_PHONE.title : <Link href={CONTACT_PHONE.path} variant={LinkVariants.BLACK_TO_PURPLE}>
                  {CONTACT_PHONE.title}
                </Link>}
            </Typography>
          </Button>}>
        <ListGroup>
          <LinkList items={isResponsMode ? PHONES_ITEMS : PHONES_ITEMS.filter((_, i) => i !== 0)} variant={LinkVariants.BLACK_TO_PURPLE} />
        </ListGroup>
      </Popover>

      <Modal closeMode={"destroy"} disclosure={<Button>Заказать звонок</Button>} title={"Заказать звонок"}>
        <LazyHydrate whenVisible={lazyHydrationConfig}>
          <Recall />
        </LazyHydrate>
      </Modal>
    </StyledCallContainer>;
};
CallInfo.displayName = "CallInfo";